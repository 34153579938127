import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, description, image, keywords, canonical }) => {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
						lang
						title
						description
						fbAppID
						googleSiteVerification
					}
				}
			}
		`
	);

	const metaDescription = description || site.siteMetadata.description;
	let metaImage = `${site.siteMetadata.siteUrl}${image || '/meta/default.jpg'}`;
	if (image.substring(0, 8) === 'https://') metaImage = image;

	return (
		<Helmet
			htmlAttributes={{ lang: site.siteMetadata.lang }}
			title={title}
			titleTemplate={`%s – ${site.siteMetadata.title}`}
			link={canonical ? [{ rel: 'canonical', href: `${site.siteMetadata.siteUrl}/${canonical}/` }] : []}
			meta={[
				{
					name: `description`,
					content: metaDescription,
				},
				{
					property: `og:title`,
					content: title,
				},
				{
					property: `og:description`,
					content: metaDescription,
				},
				{
					property: `og:type`,
					content: `website`,
				},
				{
					property: `og:image`,
					content: metaImage,
				},
				{
					property: `og:image:width`,
					content: `1200`,
				},
				{
					property: `og:image:height`,
					content: `628`,
				},
				{
					property: `og:image:alt`,
					content: title,
				},
				{
					property: `og:site_name`,
					content: site.siteMetadata.title,
				},
				{
					name: `twitter:card`,
					content: `summary_large_image`,
				},
				{
					property: `twitter:image`,
					content: metaImage,
				},
				{
					name: `twitter:title`,
					content: title,
				},
				{
					name: `twitter:description`,
					content: metaDescription,
				},
				{
					property: `fb:app_id`,
					content: site.siteMetadata.fbAppID,
				},
				{
					name: `google-site-verification`,
					content: site.siteMetadata.googleSiteVerification,
				},
			]
				.concat(
					keywords.length > 0
						? {
							name: `keywords`,
							content: keywords.join(`, `),
						}
						: []
				)}
		/>
	);
};

SEO.defaultProps = {
	description: ``,
	image: ``,
	keywords: [`wesele`, `ślub`, `organizacja wesela`, `sale weselne`, `fotograf na wesele`, `zespół na wesele`, `jak zorganizować wesele`],
};

SEO.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string,
	image: PropTypes.string,
	keywords: PropTypes.arrayOf(PropTypes.string),
	canonical: PropTypes.string,
};

export default SEO;
