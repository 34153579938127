import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { between, lighten } from 'polished';

const StyledDescription = styled.div`
	max-width: 660px;
	margin-left: auto;
	margin-right: auto;
	color: ${props => props.theme.colors.warm_grey_300};
	font-size: ${between('14px', '16px')};
	line-height: ${between('20px', '24px')};
	padding-bottom: ${between('30px', '120px')};

	a {
		color: ${props => props.theme.colors.warm_grey_300};
		text-decoration: underline;
	}

	button {
		background: none;
		padding: 16px 18px 15px 23px;
		text-transform: uppercase;
		letter-spacing: 2px;
		color: ${props => props.theme.colors.warm_grey_200};
		font-size: 12px;
		border: solid 1px ${props => props.theme.colors.golden_500};
		border-radius: 1px;
		overflow: hidden;
		z-index: 1;
		cursor: pointer;
		position: relative;
		transition: all 0.3s;

		svg {
			width: 16px;
			margin-left: 5px;
			margin-bottom: -3px;
			fill: ${props => props.theme.colors.warm_grey_200};
			transition: all 0.3s;
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			background: ${props => props.theme.colors.golden_500};
			height: 100%;
			width: 100%;
			left: 0;
			z-index: -1;
			transition: transform 0.3s;
			transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
		}

		&::before {
			bottom: 100%;
			opacity: 0;
		}

		&::after {
			background: linear-gradient(to bottom, ${props => props.theme.colors.golden_500 && lighten(0.05, props.theme.colors.golden_500)} 0%, ${props => props.theme.colors.golden_500} 100%);
			top: 100%;
		}

		&:hover,
		&:focus {
			color: ${props => props.theme.colors.warm_grey_1000};
			transition-delay: 0.3s;
			text-decoration: none;
			outline: none;

			svg {
				fill: ${props => props.theme.colors.warm_grey_1000};
				transition-delay: 0.3s;
			}

			&::before {
				transform: translate3d(0, 100%, 0);
				opacity: 0.2;
			}

			&::after {
				transform: translate3d(0, -100%, 0);
				transition-delay: 0.3s;
			}
		}
	}
`;

const HeaderDescription = ({ className, children }) => (
	<StyledDescription className={className}>
		{children}
	</StyledDescription>
);

HeaderDescription.propTypes = {
	children: PropTypes.node.isRequired,
};

export default HeaderDescription;
