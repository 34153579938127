import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledFront = styled.div`
	width: 100%;
	top: 0;
	left: 0;
	text-align: center;
	color: ${props => props.theme.colors.white};
	position: absolute;
`;

const HeaderFront = ({ className, children }) => (
	<StyledFront className={className}>
		{children}
	</StyledFront>
);

HeaderFront.propTypes = {
	children: PropTypes.node.isRequired,
};

export default HeaderFront;
