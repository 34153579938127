import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { between } from 'polished';

const StyledHeading = styled.h1`
	font-size: ${between('26px', '60px')};
`;

const HeaderHeading = ({ className, children }) => (
	<StyledHeading className={className} dangerouslySetInnerHTML={{ __html: children }} />
);

HeaderHeading.propTypes = {
	children: PropTypes.node.isRequired,
};

export default HeaderHeading;
