import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { between } from 'polished';

const StyledContainer = styled.div`
	width: 100%;
	max-width: ${props => props.theme.breakpoints.lg};
	margin-left: auto;
	margin-right: auto;
	padding: ${between('24px', '10px')};
	box-sizing: border-box;
`;

const Container = ({ className, children }) => (
	<StyledContainer className={className}>
		{children}
	</StyledContainer>
);

Container.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Container;
