import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { FaUser, FaLongArrowAltRight, FaBars, FaTimes } from 'react-icons/fa';
import { between, lighten } from 'polished';

import Container from './container';

const StyledNavigation = styled.nav`
	display: flex;
	align-items: center;
	padding-top: ${between('5px', '24px')};
	padding-bottom: ${between('5px', '40px')};

	* + * {
		margin-left: ${between('15px', '20px')};
	}

	.right {
		font-size: 12px;
		text-transform: uppercase;
		letter-spacing: 2px;
		color: ${props => props.theme.colors.warm_grey_200};
	}
`;

const Logo = styled(Link)`
	margin-right: auto;
	display: inline-flex;
	align-items: center;
	user-select: none;

	svg {
		width: 40px;
		height: auto;
		margin-right: 4px;

		path {
			transition: all 0.3s;
		}
	}

	.title {
		font-weight: 400;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin-left: 10px;
		font-size: 14px;
		color: ${props => props.theme.colors.white};
	}

	&:hover,
	&:focus {
		text-decoration: none;
		outline: none;

		path {
			fill: ${props => props.theme.colors.golden_500};
		}
	}
`;

const MenuButton = styled(FaBars)`
	padding: 5px;
	font-size: 30px;
	cursor: pointer;

	@media (min-width: ${props => props.theme.breakpoints.xs}) {
		display: none;
	}
`;

const ProfileLink = styled(Link)`
	display: none;
	align-items: center;

	@media (min-width: ${props => props.theme.breakpoints.xs}) {
		display: flex;
	}
`;

const Avatar = styled.span`
	background: ${props => props.theme.colors.white};
	width: ${between('30px', '34px')};
	height: ${between('30px', '34px')};
	line-height: ${between('30px', '34px')};
	border-radius: 50%;
	display: inline-block;
	text-align: center;
	font-size: 18px;
	color: ${props => props.theme.colors.warm_grey_500};
	margin-right: 10px;
	transition: all 0.3s;

	img {
		width: 100%;
		height: auto;
		border-radius: 50%;
	}
`;

const StyledFaUser = styled(FaUser)`
	width: ${between('12px', '14px')};
	height: ${between('12px', '14px')};
`;

const AddOfferButton = styled(Link)`
	display: none;
	padding: 10px 15px 12px 20px;
	border: solid 1px ${props => props.theme.colors.golden_500};
	border-radius: 1px;
	overflow: hidden;
	z-index: 1;
	position: relative;
	transition: all 0.3s;

	svg {
		width: 16px;
		margin-left: 5px;
		margin-bottom: -3px;
		fill: ${props => props.theme.colors.warm_grey_200};
		transition: all 0.3s;
	}

	&::before,
	&::after {
		content: '';
		position: absolute;
		background: ${props => props.theme.colors.golden_500};
		height: 100%;
		width: 100%;
		left: 0;
		z-index: -1;
		transition: transform 0.3s;
		transition-timing-function: cubic-bezier(0.75, 0, 0.125, 1);
	}

	&::before {
		bottom: 100%;
		opacity: 0;
	}

	&::after {
		background: linear-gradient(to bottom, ${props => props.theme.colors.golden_500 && lighten(0.05, props.theme.colors.golden_500)} 0%, ${props => props.theme.colors.golden_500} 100%);
		top: 100%;
	}

	&:hover,
	&:focus {
		color: ${props => props.theme.colors.warm_grey_1000};
		transition-delay: 0.3s;
		text-decoration: none;
		outline: none;

		svg {
			fill: ${props => props.theme.colors.warm_grey_1000};
			transition-delay: 0.3s;
		}

		&::before {
			transform: translate3d(0, 100%, 0);
			opacity: 0.2;
		}

		&::after {
			transform: translate3d(0, -100%, 0);
			transition-delay: 0.3s;
		}
	}

	@media (min-width: ${props => props.theme.breakpoints.xs}) {
		display: block;
	}
`;

const MobileMenu = styled.div`
	position: fixed;
	background: ${props => props.theme.colors.violet_800};
	width: 100%;
	height: 100%;
	z-index: 999;
	top: 0;
	right: 0;
	padding: 23px;
	text-align: left;
	opacity: 0.98;

	ul {
		list-style-type: none;

		li {
			display: block;
			border-top: solid 1px ${props => props.theme.colors.warm_grey_800};
			padding-top: 23px;
			padding-bottom: 23px;
			padding-left: ${between('40px', '44px')};

			a {
				display: block;
				color: ${props => props.theme.colors.white};
			}

			&:first-of-type {
				padding-left: 0;
				border: none;
			}
		}
	}

	@media (min-width: ${props => props.theme.breakpoints.xs}) {
		display: none;
	}
`;

const CloseMenuWrapper = styled.div`
	text-align: right;
	margin-bottom: 46px;
`;

const CloseMenuIcon = styled(FaTimes)`
	font-size: 30px;
	margin-top: 6px;
	cursor: pointer;
`;

class Navigation extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			avatar: '',
			hideAddOfferButton: props.hideAddOfferButton || false,
			showMobileMenu: false,
		};

		this.showMobileMenu = this.showMobileMenu.bind(this);
		this.hideMobileMenu = this.hideMobileMenu.bind(this);
	};

	componentDidMount() {
		if (typeof window !== 'undefined' && window && window.localStorage) {
			const avatar = window.localStorage.getItem('userAvatar');
			if (avatar) this.setState({ avatar });
		}
	}

	showMobileMenu() {
		this.setState({ showMobileMenu: true });
	};

	hideMobileMenu() {
		this.setState({ showMobileMenu: false });
	};

	render() {
		return (
			<Container>
				<StyledNavigation>
					<Logo to="/">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120"><path d="M164.66,0H75L40.37,32.06l79.45,87,79.45-87L164.66,0Zm-4.1,11.77,22.67,21-63.41,69.42L56.41,32.77l22.67-21h81.48Z" fill="#fff" /><path d="M124.3,0H34.61L0,32.06l79.45,87,79.46-87Zm-4.11,11.77,22.67,21L79.45,102.19,16,32.77l22.67-21h81.48Z" fill="#fff" /></svg>
						<span className="title">Weselnicy.pl</span>
					</Logo>

					<MenuButton onClick={this.showMobileMenu} />

					<ProfileLink to="/profil/" rel="nofollow" className="right">
						<Avatar>
							{this.state.avatar.length > 0 &&
								<img src={this.state.avatar} loading="lazy" alt="" width="68" height="68" />
							}
							{this.state.avatar.length <= 0 &&
								<StyledFaUser />
							}
						</Avatar>
						Moje oferty
					</ProfileLink>

					{!this.state.hideAddOfferButton &&
						<AddOfferButton to="/dodaj-oferte/" className="right">
							Dodaj ofertę <FaLongArrowAltRight />
						</AddOfferButton>
					}
				</StyledNavigation>

				{this.state.showMobileMenu &&
					<MobileMenu>
						<CloseMenuWrapper>
							<CloseMenuIcon onClick={this.hideMobileMenu} />
						</CloseMenuWrapper>

						<ul>
							<li>
								<Link to="/profil/" rel="nofollow" onClick={this.hideMobileMenu}>
									<Avatar>
										{this.state.avatar.length > 0 &&
											<img src={this.state.avatar} loading="lazy" alt="" width="68" height="68" />
										}
										{this.state.avatar.length <= 0 &&
											<StyledFaUser />
										}
									</Avatar>
									Moje oferty
								</Link>
							</li>

							{!this.state.hideAddOfferButton &&
								<li>
									<Link to="/dodaj-oferte/" onClick={this.hideMobileMenu}>
										Dodaj ofertę <FaLongArrowAltRight />
									</Link>
								</li>
							}

							<li><Link to="/cennik/" onClick={this.hideMobileMenu}>Cennik</Link></li>
							<li><Link to="/blog/" onClick={this.hideMobileMenu}>Blog</Link></li>
							<li><Link to="/miasta/" onClick={this.hideMobileMenu}>Lista miast</Link></li>
						</ul>
					</MobileMenu>
				}
			</Container>
		);
	};
};

export default Navigation;
