import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const StyledHeader = styled.header`
	background: ${props => props.theme.colors.violet_1000};
	min-height: 120px;
	position: relative;
	margin-bottom: 23px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	&::before {
		content: '';
		width: 100%;
		height: 100%;
		left: 0;
		top: 0;
		position: absolute;
		opacity: 0.85;
		background: ${props => props.theme.colors.violet_1000};
	}
`;

const HeaderSection = ({ className, children, hero }) => (
	<StyledHeader className={className + ' slant--bottom'} style={{ backgroundImage: hero ? `url(${hero})` : null }}>
		{children}
	</StyledHeader>
);

HeaderSection.propTypes = {
	children: PropTypes.node.isRequired,
};

export default HeaderSection;
