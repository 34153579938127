import React from 'react';
import { StaticQuery, Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import { between } from 'polished';

import Container from '../container';
import './page.scss';

const StyledFooter = styled.footer`
	background: ${props => props.theme.colors.violet_800};
	position: relative;
	margin-top: 23px;
	padding-top: ${between('30px', '120px')};

	.meta {
		background: ${props => props.theme.colors.violet_1000};
		margin-top: ${between('20px', '50px')};
		text-align: center;
		padding: 23px;
		font-size: 12px;
		color: ${props => props.theme.colors.warm_grey_600};

		a {
			color: ${props => props.theme.colors.warm_grey_600};
		}
	}
`;

const StyledContainer = styled(Container)`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
	grid-gap: 35px;
	padding-left: ${between('60px', '46px')};
	padding-right: ${between('60px', '46px')};

	.label {
		color: ${props => props.theme.colors.warm_grey_200};
		font-weight: 700;
		text-transform: uppercase;
		font-size: 12px;
		letter-spacing: 2px;
	}

	ul {
		list-style-type: none;

		li {
			line-height: 36px;
			font-size: 14px;

			a {
				color: ${props => props.theme.colors.warm_grey_600};

				b {
					color: ${props => props.theme.colors.golden_500};
				}
			}
		}
	}
`;

const Categories = () => (
	<StaticQuery
		query={graphql`
			query Page {
				allMysqlCategory {
					edges {
						node {
							slug
							label_nominative_plural
						}
					}
				}
			}
		`}
		render={data => (
			<ul>
				{
					data.allMysqlCategory.edges.map(({ node }, index) => (
						<li key={index}><Link to={`/${node.slug}/`}>{node.label_nominative_plural}</Link></li>
					))
				}
			</ul>
		)}
	/>
);

const Page = ({ children }) => {
	const theme = {
		colors: {
			white: 'hsl(0, 0%, 100%)',
			violet_800: 'hsl(247, 18%, 18%)',
			violet_1000: 'hsl(236, 25%, 13%)',
			golden_100: 'hsl(37, 80%, 93%)',
			golden_400: 'hsl(37, 70%, 70%)',
			golden_500: 'hsl(37, 40%, 62%)',
			golden_800: 'hsl(37, 95%, 25%)',
			warm_grey_100: 'hsl(40, 23%, 97%)',
			warm_grey_200: 'hsl(43, 13%, 90%)',
			warm_grey_300: 'hsl(40, 15%, 80%)',
			warm_grey_400: 'hsl(39, 11%, 69%)',
			warm_grey_500: 'hsl(41, 8%, 61%)',
			warm_grey_600: 'hsl(41, 8%, 48%)',
			warm_grey_700: 'hsl(41, 9%, 35%)',
			warm_grey_800: 'hsl(37, 11%, 28%)',
			warm_grey_900: 'hsl(40, 13%, 23%)',
			warm_grey_1000: 'hsl(42, 15%, 13%)',
			messenger_100: 'hsl(210, 100%, 51%)',
			messenger_500: 'hsl(213, 100%, 46%)',
			messenger_1000: 'hsl(216, 100%, 41%)',
			whatsapp_100: 'hsl(125, 57%, 61%)',
			whatsapp_500: 'hsl(129, 64%, 43%)',
			whatsapp_1000: 'hsl(130, 69%, 33%)',
			green_100: 'hsl(103, 100%, 96%)',
			green_200: 'hsl(102, 48%, 86%)',
			green_400: 'hsl(102, 41%, 64%)',
		},
		breakpoints: {
			xxs: '300px',
			xs: '576px',
			sm: '768px',
			md: '992px',
			lg: '1200px',
			xl: '1600px',
		},
		border_radius: '5px',
	};

	return (
		<ThemeProvider theme={theme}>
			<>
				{children}

				<StyledFooter className="slant--top">
					<StyledContainer>
						<div className="section">
							<span className="label">Katalog ślubny</span>
							<Categories />
						</div>

						<div className="section">
							<span className="label">Weselnicy.pl</span>
							<ul>
								<li><Link to="/dodaj-oferte/"><b>Dodaj ofertę</b></Link></li>
								<li><Link to="/cennik/">Cennik</Link></li>
								<li><a href="https://www.facebook.com/groups/2060099847433304/" target="_blank" rel="noopener noreferrer">Społeczność</a></li>
								<li><Link to="/blog/">Blog</Link></li>
								<li><Link to="/miasta/">Lista miast</Link></li>
								{/* <li><Link to="/open/">Open Startup</Link></li> */}
								<li><Link to="/regulamin/">Regulamin</Link></li>
								<li><Link to="/polityka-prywatnosci/">Polityka prywatności</Link></li>
							</ul>
						</div>
					</StyledContainer>

					<div className="meta">
						&copy; 2019 | <Link to="/">Weselnicy.pl</Link>
					</div>
				</StyledFooter>
			</>
		</ThemeProvider>
	);
};

Page.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Page;
